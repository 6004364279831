import react from "react";
import "./App.css";
import { NewBgcForm } from "./Components/NewBgcForm";
import BgcForm from "./Components/BgcForm";

function App() {


  return (
    <div className="App">
      {/* <NewBgcForm /> */}
      <BgcForm/>
    </div>
  );
}

export default App;
