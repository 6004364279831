import React, { useState,useEffect } from "react";
import imageCompression from "browser-image-compression";
import img from "../Assets/Spinner-3.gif";
import Swal from "sweetalert2";
import logo from "../Assets/hum-hai-man.png";

function BgcForm() {
    const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
    const [selectExperience, setSelectExperience] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fresher, setFresher] = useState(false);
    const [success, setSuccess] = useState(false);
    const [step, setStep] = useState(1);

    /* aplicant_id  */
    const [aplicant_id, setApplicant_Id] = useState("");

    /* first form field */

    const [name, setName] = useState("");
    const [fname, setFname] = useState("");
    const [mname, setMname] = useState("");
    const [gender, setGender] = useState("");
    const [date, setDate] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [enumber, setEnumber] = useState("");
    const [companyname, setCompanyName] = useState("");
    const [location, setLocation] = useState("");
    const [language, setLanguage] = useState("");
    const [blood, setBlood] = useState("");

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [post, setPost] = useState("");
    const [police, setPolice] = useState("");
    const [district, setDistrict] = useState("");
    const [tehsil, setTehsil] = useState("");
    const [landmark, setLandmark] = useState("");
    const [pincode, setPincode] = useState("");
    const [state, setState] = useState("");

    const [address11, setAddress11] = useState("");
    const [address22, setAddress22] = useState("");
    const [address33, setAddress33] = useState("");
    const [post1, setPost1] = useState("");
    const [police1, setPolice1] = useState("");
    const [district1, setDistrict1] = useState("");
    const [tehsil1, setTehsil1] = useState("");
    const [landmark1, setLandmark1] = useState("");
    const [pincode1, setPincode1] = useState("");
    const [state1, setState1] = useState("");

    /*second form ...... */
    const [institute, setInstitute] = useState("");
    const [qualification, setQualification] = useState("");
    const [from_edu, setFrom_Edu] = useState("");
    const [grade, setGrade] = useState("");
    const [city, setCity] = useState("");
    const [graduation, setGraduation] = useState("");
    const [to_edu, setTo_Edu] = useState("");

    const [marksheet10, setMarksheet10] = useState(null);
    const [marksheet12, setMarksheet12] = useState(null);
    const [graduation_marksheet, setGraduation_Marksheet] = useState(null);
    const [provisional_degree, setProvisional_Degree] = useState(null);


    /* third form field */
    const [reference_1_name, setReference_1_Name] = useState("");
    const [reference_1_relation, setReference_1_Relation] = useState("");
    const [reference_1_address, setReference_1_Address] = useState("");
    const [reference_1_number, setReference_1_Number] = useState("");

    const [reference_2_name, setReference_2_Name] = useState("");
    const [reference_2_relation, setReference_2_Relation] = useState("");
    const [reference_2_address, setReference_2_Address] = useState("");
    const [reference_2_number, setReference_2_Number] = useState("");

    const [reference_3_name, setReference_3_Name] = useState("");
    const [reference_3_relation, setReference_3_Relation] = useState("");
    const [reference_3_address, setReference_3_Address] = useState("");
    const [reference_3_number, setReference_3_Number] = useState("");

    const [reference_4_name, setReference_4_Name] = useState("");
    const [reference_4_relation, setReference_4_Relation] = useState("");
    const [reference_4_address, setReference_4_Address] = useState("");
    const [reference_4_number, setReference_4_Number] = useState("");

    const [errors, setErrors] = useState({});

    /* forth form field */
    const [aadhar, setAadhar] = useState("");
    const [aadhar_front_side, setAadhar_Front_Side] = useState("");
    const [aadhar_back_side, setAadhar_Back_Side] = useState("");
    const [passport_size_photo, setPassport_Size_Photo] = useState("");
    const [pan_card_number, setPan_Card_Number] = useState("");
    const [pan_card, setPan_Card] = useState("");
    const [voter_id_card, setVoter_Id_Card] = useState("");
    const [driving_license, setDriving_License] = useState("");
    const [passport, setPassport] = useState("");
    const [electricity_bill, setElectricity_Bill] = useState("");
    const [other_document, setOther_Document] = useState("");
    const [candidate, setCandidateId] = useState("");

    /*Sixth form... */
    const [tenancy_number, setTenancy_Number] = useState("");
    const [house_owner_name, setHouse_Owner_Name] = useState("");
    const [tenancy_address, setTenancy_Address] = useState("");
    const [residing_date, setResiding_date] = useState("");

    const [isOwnHouse, setIsOwnHouse] = useState(false);

    const hideTenancy = () => {
        const selected = document.getElementById("tenancy1").value;
        setIsOwnHouse(selected === "Rented");
    };

    const [formValues, setFormValues] = useState([
        {
            previous_company_name: "",
            previous_designation: "",

            hr_name: "",
            Hr_number: "",
            Hr_email_id: "",

            Employee_id: "",
            previous_salary: "",

            reason_of_leaving: "",
            previous_manager: "",

            previous_manager_number: "",
            previous_manager_email_id: "",

            from_previous_company: "",
            to_previous_company: "",

            location: "",
            work_reponsibility: "",

            payslip: "",
            pay_slip2: "",
            pay_slip3: "",

            offer_letter: "",
            resignation: "",
            experience_letter: "",
        },
    ]);


    const handleChange = (index, event) => {
        const { name, value } = event.target;
        if (name === "previous_salary" && value.includes(".")) {
            setError("Values cannot be entered in the points field");
        } else {
            setError("");
        }
        const updatedFormValues = [...formValues];
        updatedFormValues[index][name] = value;
        setFormValues(updatedFormValues);
    };

    const handleFileChange1 = async (event, index, fieldName) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const fileType = file.type;

                if (
                    fileType === "application/pdf" ||
                    fileType === "image/jpeg" ||
                    fileType === "image/png" ||
                    fileType === "image/jpg"
                ) {
                    let fileToSet = file;
                    if (fileType !== "application/pdf") {
                        const options = {
                            maxSizeMB: 0.2,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                        };
                        fileToSet = await imageCompression(file, options);
                    }
                    const updatedFormValues = [...formValues];
                    updatedFormValues[index][fieldName] = fileToSet;
                    setFormValues(updatedFormValues);
                } else {
                    console.error(
                        "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
                    );
                }
            } catch (error) {
                console.error("Error processing file:", error);
            }
        }
    };

    const [error, setError] = useState("");

    const handleFileChange = async (event, setStateFunction) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const fileType = file.type;

                if (
                    fileType === "application/pdf" ||
                    fileType === "image/jpeg" ||
                    fileType === "image/png" ||
                    fileType === "image/jpg"
                ) {
                    if (fileType === "application/pdf") {
                        setStateFunction(file);
                    } else {
                        const options = {
                            maxSizeMB: 0.2,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                        };

                        const compressedFile = await imageCompression(file, options);
                        setStateFunction(compressedFile);
                    }
                } else {
                    console.error(
                        "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
                    );
                }
            } catch (error) {
                console.error("Error processing file:", error);
            }
        }
    };


    const billingFunction = () => {
        setIsSameAddressChecked((prevValue) => !prevValue);
        if (isSameAddressChecked) {
            setAddress11("");
            setAddress22("");
            setAddress33("");
            setPost1("");
            setPolice1("");
            setDistrict1("");
            setTehsil1("");
            setLandmark1("");
            setPincode1("");
            setState1("");
        } else {
            setAddress11(address1);
            setAddress22(address2);
            setAddress33(address3);
            setPost1(post);
            setPolice1(police);
            setDistrict1(district);
            setTehsil1(tehsil);
            setLandmark1(landmark);
            setPincode1(pincode);
            setState1(state);
        }
    };

    // Step headings
    const steps = ["Personal Details", "Education", "Reference", "Documents", "Tanancy", "Employement"];

    // Move to previous step
    const prevStep = () => {
        if (step > 1) setStep(step - 1);
    };
    /*first page next......... */
    const nextStep1 = () => {
        if (
            name &&
            fname &&
            mname &&
            gender &&
            date &&
            email &&
            number &&
            enumber &&
            companyname &&
            location &&
            address1 &&
            post &&
            police &&
            district &&
            tehsil &&
            landmark &&
            pincode &&
            state &&
            address11 &&
            post1 &&
            police1 &&
            district1 &&
            tehsil1 &&
            landmark1 &&
            pincode1 &&
            state1
        ) {
            const emailR =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (emailR.test(email)) {
                setStep((prevStep) => prevStep + 1);
            } else {
                alert("Please enter a valid email address");
            }
        } else {
            alert("Please fill out all required fields");
        }
    };

    /*Second page next......... */
    const nextStep2 = () => {
        setStep((prevStep) => prevStep + 1);
      };

    const createFileObject = (image, name = "image.png") => {
        return new File([image], name, { type: "image/png" });
      };
    
      useEffect(() => {
        const fileObject = createFileObject(logo, "hum-hai-man.png");
        setMarksheet10(fileObject);
        setMarksheet12(fileObject);
        setProvisional_Degree(fileObject);
        setGraduation_Marksheet(fileObject);
      }, []);

    const nextStep3 = () => {
        const newErrors = {};
        if (!reference_1_name) newErrors.reference_1_name = "Name is required.";
        if (!reference_1_relation)
            newErrors.reference_1_relation = "Relation is required.";
        if (!reference_1_number) {
            newErrors.reference_1_number = "Contact number is required.";
        } else if (!/^\d{10}$/.test(reference_1_number)) {
            newErrors.reference_1_number = "Enter a valid 10-digit number.";
        }
        if (!reference_1_address)
            newErrors.reference_1_address = "Address is required.";

        if (!reference_2_name) newErrors.reference_2_name = "Name is required.";
        if (!reference_2_relation)
            newErrors.reference_2_relation = "Relation is required.";
        if (!reference_2_number) {
            newErrors.reference_2_number = "Contact number is required.";
        } else if (!/^\d{10}$/.test(reference_2_number)) {
            newErrors.reference_2_number = "Enter a valid 10-digit number.";
        }
        if (!reference_2_address)
            newErrors.reference_2_address = "Address is required.";

        if (!reference_3_name) newErrors.reference_3_name = "Name is required.";
        if (!reference_3_relation)
            newErrors.reference_3_relation = "Relation is required.";
        if (!reference_3_number) {
            newErrors.reference_3_number = "Contact number is required.";
        } else if (!/^\d{10}$/.test(reference_3_number)) {
            newErrors.reference_3_number = "Enter a valid 10-digit number.";
        }
        if (!reference_3_address)
            newErrors.reference_3_address = "Address is required.";

        if (!reference_4_name) newErrors.reference_4_name = "Name is required.";
        if (!reference_4_relation)
            newErrors.reference_4_relation = "Relation is required.";
        if (!reference_4_number) {
            newErrors.reference_4_number = "Contact number is required.";
        } else if (!/^\d{10}$/.test(reference_4_number)) {
            newErrors.reference_4_number = "Enter a valid 10-digit number.";
        }
        if (!reference_4_address)
            newErrors.reference_4_address = "Address is required.";

        // Check for duplicate numbers
        const numbers = [
            reference_1_number,
            reference_2_number,
            reference_3_number,
            reference_4_number,
        ];
        const duplicateNumbers = numbers.filter(
            (number, index) => numbers.indexOf(number) !== index
        );

        if (duplicateNumbers.length > 0) {
            newErrors.duplicate = "Duplicate contact numbers are not allowed.";
            alert("Duplicate contact numbers are not allowed.");
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    /*Fourth page next......... */
    const nextStep4 = () => {
        if (
          aadhar &&
          aadhar_front_side &&
          aadhar_back_side &&
          passport_size_photo &&
          pan_card_number &&
          driving_license
        ) {
          setStep((prevStep) => prevStep + 1);
        } else {
          alert("Please fill out all required fields");
        }
      };

    /*fifth page form.... */
    const nextStep5 = (e) => {
        e.preventDefault();

        const selectedTenancy = document.getElementById("tenancy1").value;
        if (!selectedTenancy) {
            alert("Please choose a tenancy option.");
            return;
        }
        if (selectedTenancy === "Rented") {
            if (
                !house_owner_name ||
                !tenancy_address ||
                !tenancy_number ||
                !residing_date
            ) {
                alert("Please fill out all required fields for rented tenancy.");
                return;
            }
        }
        BgcFormSubmit(e);
    };

    const nextStep6 = () => {
        setStep((prevStep) => prevStep + 5);
    };

    const BgcFormSubmit = async (e) => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append("full_name", name);
        formData.append("fathers_name", fname);
        formData.append("mothers_name", mname);
        formData.append("gender", gender);
        formData.append("dob", date);
        formData.append("email", email);
        formData.append("contact_number", number);
        formData.append("emergency_number", enumber);
        formData.append("name_of_the_client", companyname);
        formData.append("client_location", location);
        formData.append("language_known", language);
        formData.append("blood_group", blood);

        formData.append("permanent_village_city", address1);
        formData.append("permanent_village_city2", address2);
        formData.append("permanent_village_city3", address3);
        formData.append("permanent_post_office", post);
        formData.append("permanent_police_station", police);
        formData.append("permanent_district", district);
        formData.append("permanent_tehsil", tehsil);
        formData.append("permanent_landmark", landmark);
        formData.append("permanent_pincode", pincode);
        formData.append("permanent_state", state);

        formData.append("current_village_city", address11);
        formData.append("current_village_city2", address22);
        formData.append("current_village_city3", address33);
        formData.append("current_post_office", post1);
        formData.append("current_police_station", police1);
        formData.append("current_district", district1);
        formData.append("current_tehsil", tehsil1);
        formData.append("current_landmark", landmark1);
        formData.append("current_pincode", pincode1);
        formData.append("current_state", state1);

        formData.append("institute_name", institute);
        formData.append("qualification", qualification);
        formData.append("from_edu", from_edu);
        formData.append("to_edu", to_edu);
        formData.append("grade_edu", grade);
        formData.append("city_edu", city);
        formData.append("marksheet_10", marksheet10);
        formData.append("marksheet_12", marksheet12);
        formData.append("higher_education", graduation);
        formData.append("higher_education_marksheet", graduation_marksheet);
        formData.append("higher_education_degree_provisional", provisional_degree);
        formData.append("ref_name1", reference_1_name);
        formData.append("ref_relation1", reference_1_relation);
        formData.append("ref_contact1", reference_1_number);
        formData.append("ref_address1", reference_1_address);
        formData.append("ref_name2", reference_2_name);
        formData.append("ref_relation2", reference_2_relation);
        formData.append("ref_contact2", reference_2_number);
        formData.append("ref_address2", reference_2_address);
        formData.append("ref_name3", reference_3_name);
        formData.append("ref_relation3", reference_3_relation);
        formData.append("ref_contact3", reference_3_number);
        formData.append("ref_address3", reference_3_address);
        formData.append("ref_name4", reference_4_name);
        formData.append("ref_relation4", reference_4_relation);
        formData.append("ref_contact4", reference_4_number);
        formData.append("ref_address4", reference_4_address);
        formData.append("adhar_number", aadhar);
        formData.append("adhar_frontside", aadhar_front_side);
        formData.append("adhar_backside", aadhar_back_side);
        formData.append("photo", passport_size_photo);
        formData.append("pancard_number", pan_card_number);
        formData.append("pan_card", pan_card);
        formData.append("voter_di", voter_id_card);
        formData.append("driving_license", driving_license);
        formData.append("passport", passport);
        formData.append("Telephone_electricity", electricity_bill);
        formData.append("other_document", other_document);
        formData.append("house_owner_name", house_owner_name);
        formData.append("tenancy_contact", tenancy_number);
        formData.append("address", tenancy_address);
        formData.append("residing_date", residing_date);

        try {
            let response = await fetch(
                "https://humhai.in/background-verification/featured-form/",
                {
                    method: "POST",
                    body: formData,
                }
            );

            let data = await response.json();
            setCandidateId(data.id);

            if (data.status === 200) {
                setIsLoading(false);
                setStep((prevStep) => prevStep + 1);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const EmploymentSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const isAnyFieldEmpty = formValues.some((form) =>
            Object.entries(form).some(([key, value]) => {
                if (typeof value === "string" && value.trim() === "") {
                    return true;
                }

                if (key.includes("email") && value.trim() !== "") {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value)) {
                        alert("Please enter a valid email address");
                        return true;
                    }
                }

                return false;
            })
        );

        if (isAnyFieldEmpty) {
            alert("Please fill all required fields");
            setIsLoading(false);
            return;
        }
        if (formValues.some((form) => form.previous_salary.includes("."))) {
            alert("Please enter a valid numeric value. Points cannot be used.");
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formValues.forEach((form, index) => {
            formData.append(`previous_company_name`, form.previous_company_name);
            formData.append(`previous_designation`, form.previous_designation);

            formData.append(`hr_name`, form.hr_name);
            formData.append(`Hr_number`, form.Hr_number);
            formData.append(`Hr_email_id`, form.Hr_email_id);

            formData.append(`Employee_id`, form.Employee_id);
            formData.append(`previous_salary`, form.previous_salary);

            formData.append(`reason_of_leaving`, form.reason_of_leaving);
            formData.append(`previous_manager`, form.previous_manager);

            formData.append(`previous_manager_number`, form.previous_manager_number);
            formData.append(
                `previous_manager_email_id`,
                form.previous_manager_email_id
            );

            formData.append(`from_previous_company`, form.from_previous_company);
            formData.append(`to_previous_company`, form.to_previous_company);

            formData.append(`location`, form.location);
            formData.append(`work_reponsibility`, form.work_reponsibility);

            formData.append(`payslip`, form.payslip);
            formData.append(`pay_slip2`, form.pay_slip2);
            formData.append(`pay_slip3`, form.pay_slip3);

            formData.append(`offer_letter`, form.offer_letter);
            formData.append(`resignation`, form.resignation);
            formData.append(`experience_letter`, form.experience_letter);
        });
        formData.append("candidate", candidate);
        formData.append("fresher", fresher);

        let response = await fetch(
            "https://humhai.in/background-verification/employment",
            {
                method: "POST",
                body: formData,
            }
        )
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error;
            });
        let data = await response.json();
        if (data.status === 200) {
            addFormFields();
            setIsLoading(false);
        } else {
            Swal.fire({
                icon: "error",
                title: response.message,
            });
            setIsLoading(false);
        }
    };

    const EmploymentSubmit1 = async (e) => {
        setIsLoading(true);
        const isAnyFieldEmpty = formValues.some((form) =>
            Object.entries(form).some(([key, value]) => {
                if (typeof value === "string" && value.trim() === "") {
                    return true;
                }

                if (key.includes("email") && value.trim() !== "") {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value)) {
                        alert("Please enter a valid email address");
                        return true;
                    }
                }

                return false;
            })
        );

        if (isAnyFieldEmpty) {
            alert("Please fill all required fields");
            setIsLoading(false);
            return;
        }
        if (formValues.some((form) => form.previous_salary.includes("."))) {
            alert("Please enter a valid numeric value. Points cannot be used.");
            setIsLoading(false);
            return;
        }
        const formData = new FormData();
        formValues.forEach((form, index) => {
            formData.append(`previous_company_name`, form.previous_company_name);
            formData.append(`previous_designation`, form.previous_designation);

            formData.append(`hr_name`, form.hr_name);
            formData.append(`Hr_number`, form.Hr_number);
            formData.append(`Hr_email_id`, form.Hr_email_id);

            formData.append(`Employee_id`, form.Employee_id);
            formData.append(`previous_salary`, form.previous_salary);

            formData.append(`reason_of_leaving`, form.reason_of_leaving);
            formData.append(`previous_manager`, form.previous_manager);

            formData.append(`previous_manager_number`, form.previous_manager_number);
            formData.append(
                `previous_manager_email_id`,
                form.previous_manager_email_id
            );

            formData.append(`from_previous_company`, form.from_previous_company);
            formData.append(`to_previous_company`, form.to_previous_company);

            formData.append(`location`, form.location);
            formData.append(`work_reponsibility`, form.work_reponsibility);

            formData.append(`payslip`, form.payslip);
            formData.append(`pay_slip2`, form.pay_slip2);
            formData.append(`pay_slip3`, form.pay_slip3);

            formData.append(`offer_letter`, form.offer_letter);
            formData.append(`resignation`, form.resignation);
            formData.append(`experience_letter`, form.experience_letter);
        });

        formData.append("candidate", candidate);
        formData.append("fresher", fresher);

        try {
            let response = await fetch(
                "https://humhai.in/background-verification/employment",
                {
                    method: "POST",
                    body: formData,
                }
            );
            let data = await response.json();

            if (data.status === 200) {
                alert(`Your form has been successfully submitted.`);
                setIsLoading(false);
                window.location.reload();
            } else {
                throw new Error(data.message || "Failed to submit data");
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message || "Failed to submit data",
            });
            setIsLoading(false);
        }
    };

    const checkAplicantId = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("reference_id", aplicant_id);

        try {
            let response = await fetch(
                "https://humhai.in/background-verification/featured-form/",
                {
                    method: "POST",
                    body: formData,
                }
            );
            let data = await response.json();
            setCandidateId(data.id);

            if (response.ok) {
                setIsLoading(false);
                nextStep6();
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
            });
            setSuccess(false);
            setIsLoading(false);
        }
    };

    const hideEmployment = () => {
        const selected = document.getElementById("employment1").value;
        if (selected === "Experience") {
            setSelectExperience("Experience");
            setFresher(false);
        } else if (selected === "Fresher") {
            const confirmed = window.confirm(
                "Are You Sure You Want To Submit This Form As Fresher ?"
            );
            if (confirmed) {
                setFresher(true);
                EmploymentSubmit2(true);
            } else {
                console.log("Cancel clicked");
            }
        } else {
            setSelectExperience("");
        }
    };

    const EmploymentSubmit2 = async (fresherValue) => {
        const formData = new FormData();
        formData.append("fresher", fresherValue);
        formData.append("candidate", candidate);

        try {
            let response = await fetch(
                "https://humhai.in/background-verification/employment",
                {
                    method: "POST",
                    body: formData,
                }
            );
            let data = await response.json();

            if (data.status === 200) {
                alert(`Your form has been successfully submitted.`);
                Swal.fire({
                    icon: "success",
                    title: response.message,
                });
                setSuccess(false);
                window.location.reload();
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
            });
            setSuccess(false);
        }
    };

    let addFormFields = () => {
        setFormValues([
            ...formValues,
            {
                previous_company_name: "",
                previous_designation: "",

                hr_name: "",
                Hr_number: "",
                Hr_email_id: "",

                Employee_id: "",
                previous_salary: "",

                reason_of_leaving: "",
                previous_manager: "",

                previous_manager_number: "",
                previous_manager_email_id: "",

                from_previous_company: "",
                to_previous_company: "",

                location: "",
                work_reponsibility: "",

                payslip: "",
                pay_slip2: "",
                pay_slip3: "",

                offer_letter: "",
                resignation: "",
                experience_letter: "",
            },
        ]);
    };

    return (
        <div className="form-container">
            {/* Header Section */}
            <div className="form-header">
                {steps.map((heading, index) => (
                    <div
                        key={index}
                        className={`step-title ${step === index + 1 ? "active" : "blurred"}`}
                    >
                        {heading}

                    </div>
                ))}
            </div>

            {/* Form Content */}
            <div className="form-content">
                {step === 1 && <div>

                    <div className="bg-light p-3 rounded-lg border border-opacity-50 mx-auto p-1">
                        <div className="row mb-4">
                            <h3 className="text-center font-bold text-2xl ">
                                Enter Your <span className="text-orange-600">HH ID</span>{' '}(Optional)
                            </h3>
                            <p className="text-center mb-3">
                                If you haven't filled in your employment details, enter your HH ID to proceed directly to the employment page
                            </p>
                            <div className="col-md-6 mx-auto d-flex">
                                <input
                                    className="inputtype bg-light form-control me-2"
                                    list="name_of_applicant_id"
                                    value={aplicant_id}
                                    onChange={(e) => setApplicant_Id(e.target.value)}
                                    name="name_of_applicant_id"
                                    maxLength="100"
                                    required
                                />
                                <button type="button" className="tablinks" onClick={checkAplicantId}>
                                    Next
                                </button>
                            </div>
                            {isLoading && (
                                <div className="text-center mt-3">
                                    <img src={img} alt="Loading..." />
                                </div>
                            )}
                        </div>

                        <h2 className="text-center font-bold text-3xl mb-3">
                            CANDIDATE{" "}
                            <span className="text-orange-600">PERSONAL DETAILS</span>
                        </h2>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="name_of_applicant" className="form-label">
                                    Name of the Applicant
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    name="name_of_applicant"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input2" className="form-label">
                                    Fathers/Husband/Wife Name
                                </label>
                                <input
                                    className="inputtype form-control mb-3"
                                    list="projects"
                                    value={fname}
                                    onChange={(e) => setFname(e.target.value)}
                                    name="fathers_name"
                                    maxLength="100"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input3" className="form-label">
                                    Mother's Name
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={mname}
                                    onChange={(e) => setMname(e.target.value)}
                                    name="mothers_name"
                                    maxLength="100"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input4" className="form-label">
                                    Applicant Gender
                                </label>{" "}
                                <select
                                    className="inputtype form-control mb-3"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    name="gender"
                                >
                                    <option value="">choose</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="other">other</option>
                                </select>
                            </div>

                            <div className="col-md-4 input-icons">
                                <label htmlFor="Input5" className="form-label">
                                    Date Of Birth
                                </label>

                                <div className="date-container">
                                    <input
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        type="date"
                                        className="inputtype form-control mb-3"
                                        name="dob"
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input6" className="form-label">
                                    Email Id
                                </label>{" "}

                                <input
                                    type="email"
                                    className="inputtype form-control mb-3"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 input-icons">
                                <label htmlFor="Input5" className="form-label">
                                    Contact Number
                                </label>

                                <div className="date-container">
                                    <input
                                        type="number"
                                        className="inputtype form-control mb-3"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                        name="contact_number"
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input6" className="form-label">
                                    Emergency Contact
                                </label>

                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={enumber}
                                    onChange={(e) => setEnumber(e.target.value)}
                                    name="emergency_contact"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Company Name
                                </label>

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={companyname}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    name="name_of_client"
                                    maxLength="100"
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Company Location
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    name="client_location"
                                    maxLength="30"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Languages Known (optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    name="languages"
                                    maxLength="30"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Blood Group (optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={blood}
                                    onChange={(e) => setBlood(e.target.value)}
                                    name="blood_group"
                                    maxLength="5"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <h2>Permanent Address</h2>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 1
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    name="village_city"
                                    maxLength="25"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 2 (Optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                    name="village_city2"
                                    maxLength="25"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 3 (Optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address3}
                                    onChange={(e) => setAddress3(e.target.value)}
                                    name="village_city3"
                                    maxLength="25"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Name of Post Office
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={post}
                                    onChange={(e) => setPost(e.target.value)}
                                    name="post_office"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Police Station/Thana
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={police}
                                    onChange={(e) => setPolice(e.target.value)}
                                    name="police_station"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    District
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={district}
                                    onChange={(e) => setDistrict(e.target.value)}
                                    name="district"
                                    maxLength="100"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Tehsil
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={tehsil}
                                    onChange={(e) => setTehsil(e.target.value)}
                                    name="tehsil"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Landmark
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                    name="landmark"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Pin Code
                                </label>
                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                    name="pine_code"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    State
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    name="state"
                                    maxLength="20"
                                />
                            </div>
                        </div>

                        <div className="row mb-4 d-flex flex-row">
                            <label
                                style={{ width: "190px" }}
                                className="form-label"
                                htmlFor="same"
                            >
                                Same as above?
                            </label>
                            <input
                                style={{ width: "30px" }}
                                type="checkbox"
                                id="same"
                                name="same"
                                onChange={() => billingFunction()}
                            />
                        </div>

                        <div className="row">
                            <h2>Current Address</h2>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 1
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address11}
                                    onChange={(e) => setAddress11(e.target.value)}
                                    name="c_village_city"
                                    maxLength="25"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 2 (Optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address22}
                                    onChange={(e) => setAddress22(e.target.value)}
                                    name="c_village_city2"
                                    maxLength="25"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Address Line 3 (Optional)
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={address33}
                                    onChange={(e) => setAddress33(e.target.value)}
                                    name="c_village_city3"
                                    maxLength="25"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Name of Post Office
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={post1}
                                    onChange={(e) => setPost1(e.target.value)}
                                    name="c_post_office"
                                    maxLength="100"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Police Station
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={police1}
                                    onChange={(e) => setPolice1(e.target.value)}
                                    name="c_police_station"
                                    maxLength="100"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    District
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={district1}
                                    onChange={(e) => setDistrict1(e.target.value)}
                                    name="c_district"
                                    maxLength="100"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Tehsil
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={tehsil1}
                                    onChange={(e) => setTehsil1(e.target.value)}
                                    name="c_tehsil"
                                    maxLength="100"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Landmark
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={landmark1}
                                    onChange={(e) => setLandmark1(e.target.value)}
                                    name="c_landmark"
                                    maxLength="100"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    Pin Code
                                </label>
                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={pincode1}
                                    onChange={(e) => setPincode1(e.target.value)}
                                    name="c_pine_code"
                                    maxLength="100"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="Input7" className="form-label">
                                    State
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={state1}
                                    onChange={(e) => setState1(e.target.value)}
                                    name="c_state"
                                    maxLength="20"
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                        <div style={{ float: "right" }}>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                onClick={nextStep1}
                            >
                                Next
                            </button>
                        </div>
                    </div>

                </div>}

                {step === 2 && <div>
                    <div className="bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
                        <div className="text-center font-bold text-3xl">🎓 CANDIDATE <span style={{ color: "rgb(255, 119, 0)" }}>{" "}EDUCATION</span>{" "}DETAILS{' '}(Optional)
                        </div>
                        <h5 className=" text-center" style={{ color: "rgb(255, 119, 0)" }}>
                            * Upload a Fully Clear and Completed Document *
                        </h5>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="organisation">Institute Name</label>
                                <input
                                    type="text"
                                    value={institute}
                                    onChange={(e) => setInstitute(e.target.value)}
                                    name="institute_name"
                                    className="inputtype form-control mb-3"
                                    maxLength="100"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="designation">Qualification</label>
                                <input
                                    type="text"
                                    value={qualification}
                                    onChange={(e) => setQualification(e.target.value)}
                                    name="qualification"
                                    className="inputtype form-control mb-3"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="organisation">Grades</label>
                                <input
                                    type="text"
                                    value={grade}
                                    onChange={(e) => setGrade(e.target.value)}
                                    name="grade_edu"
                                    className="inputtype form-control mb-3"
                                    maxLength="10"
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-4 col-6">
                                <label htmlFor="organisation">From</label>
                                <input
                                    type="date"
                                    value={from_edu}
                                    onChange={(e) => setFrom_Edu(e.target.value)}
                                    name="from_edu"
                                    className="inputtype form-control mb-3"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="designation">To</label>
                                <input
                                    type="date"
                                    value={to_edu}
                                    onChange={(e) => setTo_Edu(e.target.value)}
                                    name="to_edu"
                                    className="inputtype form-control mb-3"
                                />
                            </div>



                            <div className="col-md-4 col-6">
                                <label htmlFor="City">City</label>
                                <input
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    name="city_edu"
                                    className="inputtype form-control mb-3"
                                    maxLength="20"
                                />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-md-4 col-6">
                                <label htmlFor="10th Marksheet">10th Marksheet</label>
                                <span style={{ color: "red", fontSize: "small" }}>(use .jpg and .png format)</span>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(e, setMarksheet10)}
                                    accept=".png, .jpg"
                                    name="marksheet_10"
                                    className="inputtype form-control mb-3"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="12th Marksheet">12th Marksheet</label>
                                <span style={{ color: "red", fontSize: "small" }}>{" "}(use .jpg and .png format)</span>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(e, setMarksheet12)}
                                    name="marksheet_12"
                                    accept=".png, .jpg"
                                    className="inputtype form-control mb-3"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="10th Marksheet">Graduation/Post Graduation</label>
                                <select
                                    className="form-control"
                                    name="higher_education"
                                    value={graduation}
                                    onChange={(e) => setGraduation(e.target.value)}
                                >
                                    <option value="">Choose</option>
                                    <option value="BA">BA</option>
                                    <option value="B.COM">B.COM</option>
                                    <option value="BSc">BSc</option>
                                    <option value="MA">MA</option>
                                    <option value="MBA">MBA</option>
                                    <option value="B.TECH">B.TECH</option>
                                    <option value="M.TECH">M.TECH</option>
                                    <option value="M.COM">M.COM</option>
                                    <option value="M.Sc">M.Sc</option>
                                    <option value="other" id="otherprogram">
                                        Other program
                                    </option>
                                </select>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-4 col-6">
                                <label htmlFor="12th Marksheet">
                                    Graduation/Post Graduation Marksheet
                                </label>
                                <span style={{ color: "red", fontSize: "small" }}>{" "}(use .jpg and .png format)</span>
                                <input
                                    type="file"
                                    onChange={(e) =>
                                        handleFileChange(e, setGraduation_Marksheet)
                                    }
                                    accept=".png, .jpg"
                                    name="higher_education_marksheet"
                                    className="inputtype form-control mb-3"
                                />
                            </div>

                            <div className="col-md-4 col-6">
                                <label htmlFor="12th Marksheet"> Graduation/Post Graduation Degree/Provisional Degree</label>
                                <span style={{ color: "red", fontSize: "small" }}>{" "}(use .jpg and .png format)</span>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(e, setProvisional_Degree)}
                                    name="higher_education_degree_provisional"
                                    accept=".png, .jpg"
                                    className="inputtype form-control mb-3"
                                />
                            </div>
                        </div>

                    </div>
                    <div style={{ overflow: "auto" }}>
                        <div style={{ float: "right" }}>
                            <button
                                type="button"
                                style={{ backgroundColor: "#BBBBBB" }}
                                className="tablinks mb-2 ms-2"
                                onClick={prevStep}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                onClick={nextStep2}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
                {step === 3 && <div>
                    <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                        <div className="text-center font-bold text-3xl">🤝 CANDIDATE<span style={{ color: "rgb(255, 119, 0)" }}>{" "}
                            REFERENCES</span>{" "}DETAILS
                        </div>
                        <h5 className=" text-center" style={{ color: "rgb(255, 119, 0)" }}>* Duplicate contact numbers are not allowed. *</h5>
                        <hr />
                        <div className="ref1 mb-4 text-center">
                            <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 1</h4>
                            <h5>Please provide references from your family or close relatives only.</h5>
                        </div>
                        <hr className="ms-5 me-5 mb-4" />
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_1_name}
                                    onChange={(e) => setReference_1_Name(e.target.value)}
                                    name="ref_name1"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input14" className="form-label">
                                    Relation
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_1_relation}
                                    onChange={(e) => setReference_1_Relation(e.target.value)}
                                    name="ref_relation1"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input15" className="form-label">
                                    Contact No.
                                </label>
                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={reference_1_number}
                                    onChange={(e) => setReference_1_Number(e.target.value)}
                                    name="ref_conatact1"
                                />
                                {errors.reference_1_number && (
                                    <div className="text-danger">
                                        {errors.reference_1_number}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_1_address}
                                    onChange={(e) => setReference_1_Address(e.target.value)}
                                    name="ref_address1"
                                    maxLength="100"
                                />
                            </div>
                        </div>

                        <hr className="ms-5 me-5" />
                        <div className="ref2 mb-4 text-center">
                            <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 2</h4>
                            <h5>Please provide references from your family or close relatives only.</h5>
                        </div>
                        <hr className="ms-5 me-5 mb-4" />
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Name
                                </label>{" "}

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_2_name}
                                    onChange={(e) => setReference_2_Name(e.target.value)}
                                    name="ref_name2"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input14" className="form-label">
                                    Relation
                                </label>{" "}

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_2_relation}
                                    onChange={(e) => setReference_2_Relation(e.target.value)}
                                    name="ref_relation2"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input15" className="form-label">
                                    Contact No.
                                </label>

                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={reference_2_number}
                                    onChange={(e) => setReference_2_Number(e.target.value)}
                                    name="ref_contact2"
                                />
                                {errors.reference_2_number && (
                                    <div className="text-danger">
                                        {errors.reference_2_number}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Address
                                </label>{" "}

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_2_address}
                                    onChange={(e) => setReference_2_Address(e.target.value)}
                                    name="ref_address2"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <hr className="ms-5 me-5" />
                        <div className="ref3 mb-4 text-center">
                            <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 3</h4>
                            <h5>Please provide a reference from your friend or a colleague only.</h5>
                        </div>
                        <hr className="ms-5 me-5 mb-4" />
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Name
                                </label>{" "}

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_3_name}
                                    onChange={(e) => setReference_3_Name(e.target.value)}
                                    name="ref_name3"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input14" className="form-label">
                                    Relation
                                </label>{" "}

                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_3_relation}
                                    onChange={(e) => setReference_3_Relation(e.target.value)}
                                    name="ref_relation3"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input15" className="form-label">
                                    Contact No.
                                </label>

                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={reference_3_number}
                                    onChange={(e) => setReference_3_Number(e.target.value)}
                                    name="ref_contact3"
                                />
                                {errors.reference_3_number && (
                                    <div className="text-danger">
                                        {errors.reference_3_number}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_3_address}
                                    onChange={(e) => setReference_3_Address(e.target.value)}
                                    name="ref_address3"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <hr className="ms-5 me-5" />
                        <div className="ref4 mb-4 text-center">
                            <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 4</h4>
                            <h5>Please provide a reference from your friend or a colleague only.</h5>
                        </div>
                        <hr className="ms-5 me-5 mb-4" />
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_4_name}
                                    onChange={(e) => setReference_4_Name(e.target.value)}
                                    name="ref_name4"
                                    maxLength="100"
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input14" className="form-label">
                                    Relation
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_4_relation}
                                    onChange={(e) => setReference_4_Relation(e.target.value)}
                                    name="ref_relation4"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="Input15" className="form-label">
                                    Contact No.
                                </label>
                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={reference_4_number}
                                    onChange={(e) => setReference_4_Number(e.target.value)}
                                    name="ref_contact4"
                                />
                                {errors.reference_4_number && (
                                    <div className="text-danger">
                                        {errors.reference_4_number}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="Input13" className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={reference_4_address}
                                    onChange={(e) => setReference_4_Address(e.target.value)}
                                    name="ref_address4"
                                    maxLength="100"
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                        <div style={{ float: "right" }}>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                style={{ backgroundColor: "#BBBBBB" }}
                                onClick={prevStep}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                onClick={nextStep3}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
                {step === 4 && <div>
                    <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                        <div className="text-center font-bold text-3xl" >📄 CANDIDATE
                            <span style={{ color: "rgb(255, 119, 0)" }}>{" "}DOCUMENTS{" "}</span>{" "}DETAILS
                        </div>
                        <h5 className=" text-center" style={{ color: "rgb(255, 119, 0)" }}>
                            * Upload a Fully Clear and Completed Document *
                        </h5>
                        <h4 style={{ color: "red", textAlign: "center" }}>* use .jpg and .png format * </h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="Input7" className="form-label">Aadhar Card Number</label>
                                <input
                                    type="number"
                                    className="inputtype form-control mb-3"
                                    value={aadhar}
                                    onChange={(e) => setAadhar(e.target.value)}
                                    name="aadhar_card"
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="Input8" className="form-label">Aadhar Card FRONT SIDE</label>
                                <input
                                    type="file"
                                    className="inputtype form-control mb-3"
                                    onChange={(e) =>
                                        handleFileChange(e, setAadhar_Front_Side)
                                    }
                                    accept=".png, .jpg"
                                    name="aadhar_front"
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="Input9" className="form-label">Aadhar Card BACK SIDE</label>
                                <input
                                    type="file"
                                    className="inputtype form-control mb-3"
                                    onChange={(e) => handleFileChange(e, setAadhar_Back_Side)}
                                    name="aadhar_back"
                                    accept=".png, .jpg"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="Input9" className="form-label">Passport Size Photo</label>
                                <input
                                    type="file"
                                    className="inputtype form-control mb-3"
                                    onChange={(e) =>
                                        handleFileChange(e, setPassport_Size_Photo)
                                    }
                                    name="passport_photo"
                                    accept=".png, .jpg"
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="Input8" className="form-label">Pan Card Number</label>
                                <input
                                    type="text"
                                    className="inputtype form-control mb-3"
                                    value={pan_card_number}
                                    onChange={(e) => setPan_Card_Number(e.target.value)}
                                    name="pan_number"
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="Input9" className="form-label">Driving License</label>
                                <input
                                    type="file"
                                    className="inputtype form-control mb-3"
                                    onChange={(e) => handleFileChange(e, setDriving_License)}
                                    name="driving_license"
                                    accept=".png, .jpg"
                                />
                            </div>
                          
                        </div>

                    </div>
                    <div style={{ overflow: "auto" }}>
                        <div style={{ float: "right" }}>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                style={{ backgroundColor: "#BBBBBB" }}
                                onClick={prevStep}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                onClick={nextStep4}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
                {step === 5 && <div>
                    <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                        <div className="text-center font-bold text-3xl mb-3">🏠 CANDIDATE<span style={{ color: "rgb(255, 119, 0)" }}>{' '}TENANCY</span>{" "}
                            DETAILS
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <label>Tenancy</label>

                                <select
                                    className="form-control mt-3"
                                    name="choose_tenancy"
                                    id="tenancy1"
                                    onChange={() => {
                                        hideTenancy();
                                        setHouse_Owner_Name("");
                                        setTenancy_Address("");
                                        setTenancy_Number("");
                                        setResiding_date("");
                                    }}
                                >
                                    <option value="">Choose</option>
                                    <option value="Own House">Own House</option>
                                    <option value="Rented">Rented</option>
                                </select>
                            </div>
                        </div>
                        {isOwnHouse ? (
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">House Owner Name</label>

                                        <input
                                            type="text"
                                            className="inputtype form-control mb-3"
                                            value={house_owner_name}
                                            onChange={(e) => setHouse_Owner_Name(e.target.value)}
                                            name="house_owner_name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Address</label>
                                        <input
                                            type="text"
                                            className="inputtype form-control mb-3"
                                            value={tenancy_address}
                                            onChange={(e) => setTenancy_Address(e.target.value)}
                                            name="tenancy_address"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">Contact Number</label>

                                        <input
                                            type="number"
                                            className="inputtype form-control mb-3"
                                            value={tenancy_number}
                                            onChange={(e) => setTenancy_Number(e.target.value)}
                                            name="tenancy_number"
                                        />
                                    </div>
                                    <div className="col-md-6 input-icons">
                                        <label className="form-label">Residing Date</label>

                                        <div className="date-container">
                                            <input
                                                value={residing_date}
                                                onChange={(e) => setResiding_date(e.target.value)}
                                                name="residing_date"
                                                type="date"
                                                className="inputtype form-control mb-3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="row ms-4 mb-4">
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: "1",
                                        flexDirection: "column",
                                    }}
                                >
                                    {" "}
                                    <img src={img} alt="" />
                                    <div style={{ color: "#DE3500", marginTop: "10px" }}>
                                        Please Wait ......
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                        <div style={{ float: "right" }}>
                            <button
                                type="button"
                                className="tablinks mb-2 ms-2"
                                style={{ backgroundColor: "#BBBBBB" }}
                                onClick={prevStep}
                                disabled={isLoading}
                            >
                                Previous
                            </button>
                            <button
                                className="tablinks mb-2 ms-2"
                                onClick={nextStep5}
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
                {step === 6 && <div>
                    <div className="bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
                        <div className="text-center font-bold text-3xl mb-3">💼 CANDIDATE<span style={{ color: "rgb(255, 119, 0)" }}>{" "}PREVIOUS EMPLOYEMENT</span>{" "}DETAILS
                        </div>
                        <hr />
                        <div className="col-md-4 col-sm-6">
                            <label>Employment Choose</label>

                            <select
                                className="form-control mt-3"
                                name="choose_tenancy"
                                id="employment1"
                                onChange={hideEmployment}
                            >
                                <option value="">Choose</option>
                                <option value="Fresher">Fresher</option>
                                <option value="Experience">Experience</option>
                            </select>
                        </div>
                        {selectExperience ? (
                            <div>
                                {Array.isArray(formValues) &&
                                    formValues.map((element, index) => (
                                        <div
                                            className="form-inline"
                                            key={index}
                                            disabled={index !== formValues.length - 1}
                                        >
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="organisation">Company Name</label>
                                                        <input
                                                            type="text"
                                                            value={element.previous_company_name || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="previous_company_name"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="100"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="designation">Designation</label>

                                                        <input
                                                            type="text"
                                                            value={element.previous_designation || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="previous_designation"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="100"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="Employee_id">Employee Id</label>

                                                        <input
                                                            type="text"
                                                            value={element.Employee_id || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="Employee_id"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="100"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="hr_name">HR Name</label>
                                                        <input
                                                            type="text"
                                                            value={element.hr_name || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="hr_name"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="100"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="Hr_number">HR Contact Number</label>
                                                        <input
                                                            type="number"
                                                            value={element.Hr_number || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="Hr_number"
                                                            className="inputtype form-control mb-3"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="Hr_email_id">HR Email Id</label>

                                                        <input
                                                            type="email"
                                                            value={element.Hr_email_id || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="Hr_email_id"
                                                            className="inputtype form-control mb-3"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="previous_salary">Annual CTC *</label>
                                                        <input
                                                            type="number"
                                                            value={element.previous_salary || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="previous_salary"
                                                            className="inputtype form-control mb-3"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                        {error && <p style={{ color: "red" }}>{error}</p>}
                                                    </div>

                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="reason_of_leaving">
                                                            Reason of leaving
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={element.reason_of_leaving || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="reason_of_leaving"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="255"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>


                                                    <div className="col-md-4 col-6">
                                                        <label htmlFor="designation">
                                                            Work Responsibility
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={element.work_reponsibility || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            name="work_reponsibility"
                                                            className="inputtype form-control mb-3"
                                                            maxLength="100"
                                                            disabled={index !== formValues.length - 1}
                                                        />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="previous_manager">
                                                        Supervisor/Manager Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={element.previous_manager || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="previous_manager"
                                                        className="inputtype form-control mb-3"
                                                        maxLength="100"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>

                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="previous_manager_number">
                                                        Supervisor/Manager Number *
                                                    </label>

                                                    <input
                                                        type="number"
                                                        value={element.previous_manager_number || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="previous_manager_number"
                                                        className="inputtype form-control mb-3"
                                                        maxLength="255"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>

                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="previous_manager_email_id">
                                                        Supervisor/Manager Email Id *
                                                    </label>
                                                    <input
                                                        type="email"
                                                        value={element.previous_manager_email_id || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="previous_manager_email_id"
                                                        className="inputtype form-control mb-3"
                                                        maxLength="100"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="from_previous_company">
                                                        From
                                                    </label>

                                                    <input
                                                        type="date"
                                                        value={element.from_previous_company || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="from_previous_company"
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="to_previous_company">To</label>

                                                    <input
                                                        type="date"
                                                        value={element.to_previous_company || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="to_previous_company"
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="organisation">Location</label>
                                                    <input
                                                        type="text"
                                                        value={element.location || ""}
                                                        onChange={(e) => handleChange(index, e)}
                                                        name="location"
                                                        className="inputtype form-control mb-3"
                                                        maxLength="100"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="payslip">Pay Slip 1</label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="payslip"
                                                        onChange={(e) =>
                                                            handleFileChange1(e, index, "payslip")
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                        accept=".png, .jpg"
                                                    />
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="pay_slip2">Pay Slip 2</label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="pay_slip2"
                                                        accept=".png, .jpg"
                                                        onChange={(e) =>
                                                            handleFileChange1(e, index, "pay_slip2")
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>

                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="pay_slip3">Pay Slip 3</label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="pay_slip3"
                                                        accept=".png, .jpg"
                                                        onChange={(e) =>
                                                            handleFileChange1(e, index, "pay_slip3")
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="offer_latter">Offer Letter</label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="offer_letter"
                                                        accept=".png, .jpg"
                                                        onChange={(e) =>
                                                            handleFileChange1(e, index, "offer_letter")
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <label htmlFor="resignation_latter">
                                                        Resignation Letter
                                                    </label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="resignation"
                                                        accept=".png, .jpg"
                                                        onChange={(e) =>
                                                            handleFileChange1(e, index, "resignation")
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-6"        >
                                                    <label htmlFor="resignation_latter">
                                                        Experience Letter
                                                    </label>
                                                    <span style={{ color: "red", fontSize: "small" }}>
                                                        {" "}
                                                        (use .jpg and .png format)
                                                    </span>

                                                    <input
                                                        type="file"
                                                        name="experience_letter"
                                                        accept=".png, .jpg"
                                                        onChange={(e) =>
                                                            handleFileChange1(
                                                                e,
                                                                index,
                                                                "experience_letter"
                                                            )
                                                        }
                                                        className="inputtype form-control mb-3"
                                                        disabled={index !== formValues.length - 1}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row ms-4 mb-4">
                                                {isLoading ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            zIndex: "1",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        {" "}
                                                        <img src={img} alt="" />
                                                        <div
                                                            style={{
                                                                color: "#DE3500",
                                                                marginTop: "10px",
                                                            }}
                                                        >
                                                            Please Wait ......
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                <div className="button-section">
                                    <button
                                        className="tablinks mb-2 ms-2"
                                        style={{ backgroundColor: "#FF7700" }}
                                        type="button"
                                        onClick={EmploymentSubmit}
                                        disabled={isLoading}
                                    >
                                        Add
                                    </button>
                                    <div style={{ overflow: "auto" }}>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="submit"
                                                className="tablinks mb-2 ms-2"
                                                onClick={EmploymentSubmit1}
                                                disabled={isLoading}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>}

            </div>
        </div>
    );
};
export default BgcForm;
